<!--
 * @Author: lbh
 * @Date: 2022-06-18 10:17:42
 * @LastEditors: lbh
 * @LastEditTime: 2023-07-12 16:38:51
 * @Description: 數據列表
-->
<template>
  <div class="edit-data_list-box">
    <div class="cell-b">
      <h3>標題配置</h3>
      <div class="cell-b">
        <el-input
          v-for="lItem in language"
          :key="lItem.code"
          v-model="configs[`${lItem.code=='HK'?'':lItem.code}title`]"
          :placeholder="`請輸入標題-${lItem.name}`"
          @input="setValue(`${lItem.code=='HK'?'':lItem.code}title`)"
          type="textarea"
          rows="3"
        ></el-input>
      </div>

      <h3>詳情配置</h3>
      <div class="cell-b">
        <el-input
          v-for="lItem in language"
          :key="lItem.code"
          v-model="configs[`${lItem.code=='HK'?'':lItem.code}desc`]"
          :placeholder="`請輸入詳情-${lItem.name}`"
          @input="setValue(`${lItem.code=='HK'?'':lItem.code}desc`)"
          type="textarea"
          rows="3"
        ></el-input>
      </div>
    </div>
    <template v-for="(item,index) in configs.items">
      <div class="cell-b">
        <div class="icon-box">
          <selfUpload
            v-model="item.icon"
            :radius="item.slider"
            type="square"
            @change="setValue('items')"
          />
          <!-- 操作 -->
          <el-button-group class="btn-group">
            <el-button
              type="primary"
              icon="el-icon-upload2"
              :disabled="index == 0"
              size="mini"
              @click="itemCheck(index,index-1)"
            ></el-button>
            <el-button
              type="success"
              icon="el-icon-download"
              :disabled="index == configs.items.length-1"
              size="mini"
              @click="itemCheck(index,index+1)"
            ></el-button>
            <el-button
              type="danger"
              icon="el-icon-delete"
              size="mini"
              :disabled="configs.items.length == 1"
              @click="itemDel(index)"
            ></el-button>
            <el-button
              type="warning"
              icon="el-icon-plus"
              size="mini"
              @click="itemAdd(index)"
            ></el-button>
          </el-button-group>
        </div>

        <selfCell title="圓角">
          <el-slider
            v-model="item.slider"
            @input="setValue('items')"
            style="width:100%"
          ></el-slider>
        </selfCell>
        <selfCell title="標題配置">
          <div>
            <el-input
              v-for="lItem in language"
              :key="lItem.code"
              v-model="item[`${lItem.code=='HK'?'':lItem.code}title`]"
              :placeholder="`請輸入標題-${lItem.name}`"
              @input="setValue('items')"
              type="textarea"
              rows="3"
            ></el-input>
          </div>

        </selfCell>
        <selfCell title="內容">
          <div>
            <el-input
              v-for="lItem in language"
              :key="lItem.code"
              v-model="item[`${lItem.code=='HK'?'':lItem.code}desc`]"
              :placeholder="`請輸入詳情-${lItem.name}`"
              @input="setValue('items')"
              type="textarea"
              rows="3"
            ></el-input>
          </div>

        </selfCell>

      </div>
    </template>
  </div>
</template>

<script>
import langEditMixin from '../../mixins/langEdit'
export default {
  name: "edit-dataList",
  mixins: [langEditMixin],
  props: {
    type: {
      default () {
        return "title";
      }
    },
    configs: {
      default () {
        return {
          desc: "",
          title: "",
          items: {
            icon: "",
            title: "",
            desc: ""
          }
        }
      }
    },
  },
  methods: {
    setValue (key) {
      this.$emit('setValue', { key: key, value: this.configs[key] })
    },
    // 切換順序
    itemCheck (index, newIndex) {
      let items = this.configs.items;
      let _ = items.splice(index, 1);
      items.splice(newIndex, 0, _[0])
      this.$emit('setValue', { key: 'items', value: items });
    },
    // 刪除
    itemDel (index) {
      let items = this.configs.items;
      items.splice(index, 1);
      this.$emit('setValue', { key: 'items', value: items });
    },
    // 新增
    itemAdd (index) {
      let items = this.configs.items;
      items.splice(index + 1, 0, this.$util.object.clone(items[index]))
      this.$emit('setValue', { key: 'items', value: items });
    }
  }
}
</script>

<style lang="less" scoped>
.edit-data_list-box {
  .icon-box {
    display: flex;
    align-items: center;
    justify-content: space-between;
  }
}
</style>